// global.BaseUrl = 'https://admin.foodweb-nautilius.ksoftpl.com/api';
// global.AssetsUrl = 'https://admin.foodweb-nautilius.ksoftpl.com';

global.BaseUrl = 'https://admin.foodweb-macaw.ksoftpl.com/api';
global.AssetsUrl = 'https://admin.foodweb-macaw.ksoftpl.com';

// admin.foodweb-macaw.ksoftpl.com

// global.BaseUrl = 'https://foodweb-nautilius.ksoftpl.com/nautilius-backend/public/api';
// global.AssetsUrl = 'https://foodweb-nautilius.ksoftpl.com/nautilius-backend/public';
 
// global.BaseUrl = 'http://localhost/nautilius-backend/public/api';
// global.AssetsUrl = 'http://localhost/nautilius-backend/public';