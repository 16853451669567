import React, { Component } from 'react';
import Logo from "./logo-nkp.jpg"

class Footer extends Component {
    render() {
        return (
            <>
                <footer>
                    <div className="" style={{ background: '#737070' }}>
                        <div className="container">
                            <div className="ft-widget-area footer_main_div">
                                <div className="row">
                                    <div class="col-md-3 col-sm-6">
                                        <div className="ft-area1">
                                            <div className="swin-widget swin-widget-about">
                                                <div class="clerfix">
                                                    <div className="header-logo">
                                                        <img src={Logo} alt="NKP" className="logo-img " />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-9">
                                        <div className="row">
                                            <div className="col-md-12">

                                                <div class="col-md-6 col-sm-6">
                                                    <div className="ft-area1">
                                                        <div className="swin-widget widget-about">
                                                            <div className="title-widget footer_heading">Employee Dining Enquiries</div>
                                                            <div class="about-contact-info clearfix">
                                                                <div class="info-content phone-content">
                                                                    <div class="info-icon"><i class="fa-icon fa fa-phone"></i></div>
                                                                    <div class="info-text">
                                                                        <p>(+91)-9289134415</p>
                                                                    </div>
                                                                </div>
                                                                <div class="info-content email-content">
                                                                    <div class="info-icon"><i class="fa-icon fa fa-envelope"></i></div>
                                                                    <div class="info-text">
                                                                        <p>deccancafe.hyderabad@jpmorgan.com</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-6">
                                                    <div className="ft-area1">
                                                        <div className="swin-widget widget-about">
                                                            <div className="title-widget footer_heading">Lobby Reception Enquiries</div>
                                                            <div class="about-contact-info clearfix">
                                                                <div class="info-content phone-content">
                                                                    <div class="info-icon"><i class="fa-icon fa fa-phone"></i></div>
                                                                    <div class="info-text">
                                                                        <p> +9140 – 68523747</p>
                                                                    </div>
                                                                </div>
                                                                <div class="info-content email-content">
                                                                    <div class="info-icon"><i class="fa-icon fa fa-envelope"></i></div>
                                                                    <div class="info-text">
                                                                        <p>lobbyreception.hyderabad@jpmorgan.com</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <hr />

                                        <div className="row">
                                            <div className="col-md-12">
                                                <div class="col-md-6 col-sm-6">
                                                    <div className="ft-area1">
                                                        <div className="swin-widget widget-about">
                                                            <div className="title-widget footer_heading">Conference Center Enquiries</div>
                                                            <div class="about-contact-info clearfix">
                                                                <div class="info-content phone-content">
                                                                    <div class="info-icon"><i class="fa-icon fa fa-phone"></i></div>
                                                                    <div class="info-text">
                                                                        <p> (+91)-9289134418</p>
                                                                    </div>
                                                                </div>
                                                                <div class="info-content email-content">
                                                                    <div class="info-icon"><i class="fa-icon fa fa-envelope"></i></div>
                                                                    <div class="info-text">
                                                                        <p>
                                                                        conferencecentre.hyderabad@jpmorgan.com</p>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-6 col-sm-6">
                                                    <div className="ft-area1">
                                                        <div className="swin-widget widget-about">
                                                            <div className="title-widget footer_heading">Pantry Services</div>
                                                            <div class="about-contact-info clearfix">
                                                                <div class="info-content phone-content">
                                                                    <div class="info-icon"><i class="fa-icon fa fa-phone"></i></div>
                                                                    <div class="info-text">
                                                                        <p>
                                                                        (+91)-9289134416</p>
                                                                    </div>
                                                                </div>
                                                                <div class="info-content email-content">
                                                                    <div class="info-icon">&nbsp;</div>
                                                                    <div class="info-text">
                                                                        <p>&nbsp;</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </>
        );
    }
}

export default Footer;
