import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from "jquery";
import "./Style.css";
import Logo from "./logo-nkp.jpg"
class Menu extends Component {
  constructor() {
    super();
    this.state = {
      tab2: [],
      tab3: [],
      tab4: [],
      tab5: [],
      Allergens: [],
      base_url: global.BaseUrl,

    }
  }

  componentDidMount() {

    <script>
      $(document).ready(function(){
        $(".open-offcanvas").click(function () {
          $("body").addClass("offcanvas-on");
        })

      });
      $(document).ready(function(){
        $(".close-offcanvas").click(function () {
          $("body").removeClass("offcanvas-on");
        })

      });

    </script>


    fetch(`${this.state.base_url}/tab2`).then((resp) => {
      resp.json().then((result) => {
        //  console.warn(result);
        this.setState({ tab2: result });
      })
    });

    fetch(`${this.state.base_url}/tab3`).then((resp) => {
      resp.json().then((result) => {
        //  console.warn(result);
        this.setState({ tab3: result });
      })
    });

    fetch(`${this.state.base_url}/tab4`).then((resp) => {
      resp.json().then((result) => {
        console.warn(result);
        this.setState({ tab4: result });
      })
    });


    fetch(`${this.state.base_url}/tab5`).then((resp) => {
      resp.json().then((result) => {
        console.warn(result);
        this.setState({ tab5: result });
      })
    });

    fetch(`${this.state.base_url}/allergens`).then((resp) => {
      resp.json().then((result) => {
        //  console.warn(result);
        this.setState({ Allergens: result });
      })
    });
  }
  render() {
    const { tab2, tab3, tab4, tab5, Allergens } = this.state;
    return (
      <>
        <header >

          <div className="header-main">
            <div className="container-fluid">
              <div className="open-offcanvas">☰</div>

              <div className="header-logo">
                <Link to="/" className="logo logo-static"><img src={Logo} alt="NKP" className="logo-img " /></Link>
              </div>
              <nav id="main-nav-offcanvas" className="main-nav-wrapper">
                <div className="close-offcanvas-wrapper"><span className="close-offcanvas">x</span></div>
                <div className="main-nav">
                  <ul id="main-nav" className="nav nav-pills">
                    {/* <li><Link className="close-offcanvas" to="/">Overview</Link></li> */}
                    {/* <li><Link className="close-offcanvas" to="/Lobby_Reception">Lobby Reception</Link></li> */}
                    <li><Link className="close-offcanvas" to="/">Employee Dining</Link></li>
                    <li><Link className="close-offcanvas" to="/catering-menu">Catering Menu</Link></li>
                    <li><Link className="close-offcanvas" to="/Work_Cafes">Work Cafes</Link></li>
                    <li><Link className="close-offcanvas" to="/Sustainability">Sustainability</Link></li>
                    {/* <li><Link className="close-offcanvas" to="/Healthy_Recipes">Healthy Recipes</Link></li>             */}


                    <li class="dropdown current-menu-item showhim"><Link class="dropdown-toggle">
                      Wellness</Link><i class="fa fa-angle-down btn-open-dropdown"></i>
                      <ul class="showme dropdown-content" role="menu">
                        <li class="current-menu-item drop-menu"><Link className="drop-list" to="/Healthy_Recipes">Healthy Recipes</Link></li>
                        <li class="current-menu-item drop-menu"><Link className="drop-list" to="/Seasonal_Recipes">Seasonal Recipes</Link></li>
                      </ul>
                    </li>


                    <li class="dropdown current-menu-item showhim-update "><Link class="dropdown-toggle">
                      Updates</Link><i class="fa fa-angle-down btn-open-dropdown"></i>
                      <ul className="showme-update dropdown-content" role="menu">

                        {
                          Allergens.map(Allergens_page =>
                            <li class="current-menu-item drop-menu"><Link className="drop-list" to={`${Allergens_page.sub_page}`}>{Allergens_page.sub_page}</Link></li>
                          )
                        }

                        {
                          tab2.map(page =>
                            <li class="current-menu-item drop-menu"><Link className="drop-list" to={`${page.sub_page}`}>{page.sub_page}</Link></li>
                          )
                        }
                        {
                          tab3.map(new_page =>
                            <li class="current-menu-item drop-menu"><Link className="drop-list" to={`${new_page.sub_page}`}>{new_page.sub_page}</Link></li>
                          )
                        }

                        {
                          tab4.map(new_page_new =>
                            <li class="current-menu-item drop-menu"><Link className="drop-list" to={`${new_page_new.sub_page}`}>{new_page_new.sub_page}</Link></li>
                          )
                        }

                        {
                          tab5.map(new_page_new =>
                            <li class="current-menu-item drop-menu"><Link className="drop-list" to={`${new_page_new.sub_page}`}>{new_page_new.sub_page}</Link></li>
                          )
                        }

                      </ul>
                    </li>
                    {/* <li><Link className="close-offcanvas" to="/Updates">Updates</Link></li> */}
                    {/* <li><Link className="close-offcanvas"></Link></li> */}


                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </header>

      </>
    );
  }
}

export default Menu;